table.endorsement-table{
  span{
    color: $black_blue;
  }
  thead{
    display: none;
    span{
      font-weight: 800;
      &:hover, &:focus{
        color: $blue;
      }
      svg{
        opacity: 1;
      }
    }
  }
  tbody{
    tr{
      display: flex;
      flex-wrap: wrap;
      &:not(:last-of-type){
        &:after{
          background-color: $dusty_gray_opacity;
          content: '';
          height: 1px;
          margin: auto;
          width: 95%;
        }
      }
      &:not(.card-details){
        td{
          &:nth-of-type(even){
            width: 60%;
          }
          &:nth-of-type(odd){        
            width: 40%;
            span{
              font-weight: 800;
            }
          }
        }
      }
      &.card-details{
        background-color: $silver;
        td{
          padding: 10px 5px;
          max-width: 25%;
          &:first-of-type{
            max-width: 100%;
            width: 50%;
          }
        }
      }
    }
    td{
      border: none;
      box-sizing: border-box;
      display: inline-block;
      padding: 10px 20px;
    }
  }
  @media screen and (min-width: $tablet_width_breakpoint) {
    thead{
      display: table-header-group;
    }
    tbody {
      tr{
        display: table-row;
        &:not(:last-of-type){
          &:after{
            content: none;
          }
          td{
            border-bottom: 1px solid $dusty_gray_opacity;
          }
        }
        &:not(.card-details){
          td{
            &:nth-of-type(even){
              width: auto;
            }
            &:nth-of-type(odd){        
              display: none;
            }
          }
        }
        &.card-details{
          visibility: hidden;
          height: 0;
          display: block;
          td{
            height: 0;
            display: table-caption;
            padding: 0;
            margin: 0;
          }
          &.show{
            display: table-row;
            visibility: visible;
            td{
              display: table-cell;
              height: 50px;
              padding: 16px 20px;
            }
          }
        }
        &.card-wrapper{
          cursor: pointer;
          position: relative;
          + .card-details {
            td:first-of-type{
              position: relative;
              &:before{
                visibility: visible;
                position: absolute;
                top: -33px;
                left: 5px;
                content: '';
                border-color: $jumbo;
                border-style: solid;
                border-width: 0.15em 0.15em 0 0;
                display: inline-block;
                height: 0.45em;
                transform: rotate(45deg);
                vertical-align: top;
                width: 0.45em;
              }
            }
            &.show{
              td:first-of-type{
                &:before{
                  top: -35px;
                  transform: rotate(135deg);
                }
              }
            }
          }
        }
      }
      td{
        display: table-cell;
      }
    }
  }
}
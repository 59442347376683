header {
    background: $dark_blue;
    color: $white;
    h1{
        display: none;
        color: $white;
        font-family: 'MuliExtraBold';
        font-size: 43px;
        font-weight: 100;
    }
    img{
        display: block;
        height: auto;
        margin: auto;
        width: 12%;
    }
    @media screen and (min-width: $tablet_width_breakpoint) {
        .container{
            display: grid;
            grid-template-columns: 125px 1fr;
            grid-column-gap: 70px;
        }
        h1{
            align-self: center;
            display: inline;
            margin: 0px;
        }
        img{
            margin: initial;
            max-width: 125px;
            padding: 5px 0px;
            width: 100%;
        }
    }
}
@mixin fontface($name, $file, $path, $weight: normal, $style: normal){
    @font-face {
      font-family: "#{$name}";
      src: url('#{$path}#{$file}.eot');
      src: url('#{$path}#{$file}.eot?#iefix') format('embedded-opentype'),
           url('#{$path}#{$file}.woff') format('woff'),
           url('#{$path}#{$file}.ttf') format('truetype'),
           url('#{$path}#{$file}.svg?#webfont') format('svg');
      font-weight: $weight;
      font-style: $style;
    }
  }
footer {
    background: $dark_blue;
    border-top:6px solid $turquoise;
    color: $white;
    padding: 10px 0px;
    .container{
        align-items: center;
        display: flex;
        flex-direction: column;
        >* {
            margin: 10px 0px;
        }
    }
    a{
        color: $white;
        text-decoration: none;
    }
    .logo{
        height: auto;
        width: 100px;
        order: 2;
    }
    .footer-info{
        align-items: center;
        display: flex;
        font-size: 12px;
        justify-content: space-evenly;
        order: 3;
        p{
            margin: 0;
            padding: 0px 10px;
        }
    }
    .legal-links{
        display: inline-flex;
        flex-wrap: wrap;
        order: 0;
        a{
            box-sizing: border-box;
            flex: 1 0 50%;
            font-size: 13px;
            padding: 5px;
            text-align: center;
        }
    }
    .socials{
        text-align: center;
        order: 1;
        p{
            margin-top: 0px;
            margin-bottom: 10px;
        }
        a{
            margin: 0px 5px;
        }
    }
    @media screen and (min-width: $tablet_width_breakpoint) { 
        .container{
            align-items: flex-end;
            display: grid;
            grid-template-columns: 1fr 350px;
        }
        .logo{
            order: 0;
        }
        .footer-info{
            order: 1;
        }
        .legal-links{
            order: 2;
            flex-wrap: nowrap;
            display: inline;
            a{
                flex: auto;
                padding: 0px;
                text-align: left;
                &+a{
                    margin-left: 20px;
                }
            }
        }
        .socials{
            justify-self: flex-end;
            order: 3;
            text-align: left;
            p{
                margin-left: 5px;
            }
        }
    }
    @media screen and (min-width: $desktop_width_breakpoint) {
        .footer-info{
            justify-content: space-between;
            p{
                padding: 0px 5px;
            }
        }
    }
    @media screen and (min-width: $big_desktop_width_breakpoint) {
        .container{
            grid-template-columns: 140px 300px auto 220px;
        }
        .legal-links{
            text-align: right;
          
        }
    }
}
.button{
  -webkit-appearance: button;
  background-color: $orange;
  border: none;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  color: $white;
  display: block;
  font-family: "MuliExtraBold";
  font-weight: 100;
  height: 45px;
  margin: 15px auto;
  max-width: 400px;
  min-width: 120px;
  padding: 0px;
  width: 100%;
  &:not(:disabled){
    cursor: pointer;
  }
  &:disabled{
    background-color: $jumbo;
  }
  &::-moz-focus-inner {
    border: 0;
  }
  &.back{
    background-color: $blue;
  }
  a{
    align-items: center;
    border-radius: 10px;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  @media screen and (min-width: $tablet_width_breakpoint) {
      max-width: 170px;
      margin: 15px 0px;
  }
}

.TwoRows, .Row, .ThreeRows{
    display:flex;
    flex-direction: column;
}
.RowAlignEnd{
    display:flex;
    justify-content: center;
}
@media screen and (min-width: $tablet_width_breakpoint) {
  .TwoRows{
    display:grid;
    grid-template-columns: 1fr 1fr;
  }    
  .ThreeRows{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .Row{
      display:grid;
      grid-template-columns: 1fr;
  }
  .RowAlignEnd{
      display:grid;
      grid-template-columns: 1fr;
      justify-items: end;
  }
}
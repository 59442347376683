body {
    font-family: "MuliRegular";
    margin: 0;
}
.container{
    margin: auto;
    max-width: $container_width;
    padding: 10px 15px;
}
#main{
    background-color: $silver;
    min-height: 200px;
}
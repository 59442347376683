@import "fonts";

// GLOBAL VARIABLES
$container_width: 1440px;
$tablet_width_breakpoint: 768px;
$desktop_width_breakpoint: 992px;
$big_desktop_width_breakpoint: 1200px;

// COLORS
$black: #000000;
$white: #ffffff;
$blue: #0076BE;
$dark_blue: #002B51;
$dark_blue_opacity:rgba(0,43,81,0.5);
$turquoise: #00A7B8;
$black_blue: #001629;
$jumbo: #8A8A8F;
$monza: #D0021B;
$silver:#EBEBEB;
$orange: #f57e20;
$port_gore: #1E2144;
$orange_opacity: rgba(245, 126, 32, 0.3);
$dusty_gray_opacity: rgba(151, 151, 151, 0.3);
$gray_opacity: rgba(151, 151, 151, 1);
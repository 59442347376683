.personal-card{
  background-color: $white;
  border: 1px solid $dusty_gray_opacity;
  box-sizing: border-box;
  margin: auto;
  max-width: 500px;
  padding: 20px 5px;
  text-align: center;
  img{
    margin-bottom: 15px;
  }
  .issued-title{
    border-top: 1px solid $dusty_gray_opacity;
    margin-top: 20px;
    padding-top: 20px;
  }
  .card-issued{
    margin-top: 5px;
  }
  @media screen and (min-width: $tablet_width_breakpoint) {
   text-align: left; 
   display: grid;
   max-width: none;
   grid-template-columns: 155px 1fr;
   grid-template-rows: 1fr;
   grid-column-gap: 27px;
   grid-row-gap: 0px;
   padding: 20px 15px;
   .issued-title{
     margin-bottom: 10px;
   }
    .wrapper{
      display: flex;
      flex-wrap: wrap;
      span{
        flex: 1 0 33%;
        max-width: 33%;
      }
    }
  }
}
.userTabs {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around; 
  .activeTabLabel {
    color: $turquoise;
    font-weight: 100;
  }
  .tabHeaderCheckbox  {
    .checkbox-label {
      align-items: center;
      display: flex;
      margin: 15px 0px;
      text-align: left;
      >span{
        cursor: pointer;
        &::after {
          top: 13px;
        }
      }
    }
  }
  .tabContent {
    display: flex;
    flex: 1 100%;
    margin-top: 10px;
  }


  @media screen and (min-width: $tablet_width_breakpoint) {
    text-align: left;
    width: 100%;
    max-width: none;
    padding: 20px 15px;
    position: relative;
    .tabsHeader {
      display: grid;
      grid-template-columns: 1fr max-content;
    }
    .underline {
      border-bottom: 2px solid $dusty_gray_opacity;
      margin-top: -2px;
      margin-bottom: 40px;
    }
    .tabHeaderCheckbox  {
      position: absolute;
      right: 30px;
      top: 30px;
      .checkbox-label{
        margin: 0;
      }
    }

    .tabContent {
      display: inline-block;
      width: 100%;
    }
  }
}
.error-content{
  align-items: center;
  background-color: $white;
  border: 1px solid $dusty_gray_opacity;
  color: $black_blue;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 500px);
  padding: 50px 10px;
  text-align: center;
  span{
    display: block;
    a{
      color: $turquoise;
    }
  }
}
.pagetitle{
  color: $blue;
  margin: 0px;
  font-family: "MuliExtraBold";
  font-size: 16px;
  font-weight: 100;
  &.invert{
      color: $white;
  }
}
.cardtitle{
  color: $port_gore;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
}
.cardcontent {
  color: $port_gore;
  font-size: 15px;
  line-height: 19px;
  &.bold{
    font-weight: 600;
  }
  &.block{
    display: block;
  }
}
@media screen and (min-width: $tablet_width_breakpoint) {
  .pagetitle{
    font-size: 20px;
  } 
  .cardtitle{
    font-size: 22px;
    line-height: 28px;
  } 
}
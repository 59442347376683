@import "theme";
@import "structure";
@import "../../components/Structure/structure";
@import "../../components/Button/button";
@import "../../components/Form/forms";
@import "../../components/Image/image";
@import "../../components/Card/card";
@import "../../components/Text/text";
@import "../../components/Table/table";
@import "../../components/UserTabs/UserTabs";
@import "components/header";
@import "components/footer";
@import "screens/home";
@import "screens/user-data";
@import "screens/error";

a{
    outline: none;
    text-decoration: none;
    color: inherit;
}
@import './placeholder';
form{
  >*{
    max-width: 500px;
    margin: 25px auto;
  }
  label{
    font-size:14px;
    line-height: 18px;
  }
	label:not(.checkbox-label){
		display:grid;
  }
  input:not([type="checkbox"]):not([type="submit"]):not([type="file"]), textarea{
		background-color: $silver;
		border:0;
		padding:0 15px;
		height:48px;
		display: inline-block;
    font-size:14px;
    line-height: 18px;
    
		&:focus{
				outline: none;
		}
		@include placeholder {
			font-size:14px;
			color:$dark_blue_opacity;
			font-family: "MuliSemiBold";
		}
	}
	textarea{
  height:200px;
  padding:15px;
  }
  .input-title{
    font-family:"MuliExtraBold";
    font-size:14px;
    line-height: 18px;
    font-weight: 100;
    padding-left:15px;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  @media screen and (min-width: $tablet_width_breakpoint) {
    >*{
      max-width: none;
    }
  }
}

.textInput, 
.textAreaInput{
  justify-self: stretch;
  font-family: "MuliSemiBold";
}
// Checkbox
.checkbox-label{
  position: relative;
  >span {
    display: block;
    margin-left: 25px;
    padding-left: 10px;
    margin-top: 5px;
    &::after {
      background: none;
      border-bottom: solid 3px $white;
      border-left: solid 3px $white;
      content: "";
      display: flex;
      height: 5px;
      left: 6px;
      position: absolute;
      top: 7px;
      transform: rotate(-45deg) scale(0);
      transform-origin: center center;
      transition: transform 0.1s ease, opacity 0.1s ease;
      width: 13px;
      cursor: pointer;
    }
  }
  input[type="checkbox"] {
    appearance: none;
    border: 2px solid $dusty_gray_opacity;
    border-radius: 2px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: absolute;
    transition: all 0.5 ease;
    &:focus {
      border-color: $orange;
    }
    &:checked {
      background-color: $orange;
      border-color: $orange;
      +span {
        &::after {
            opacity: 1;
            transform: rotate(-45deg) scale(0.8);
        }
      }
    }
  }
  a{
    color: $turquoise;
    font-weight: 600;
  }
}
// Select
.select {
  position: relative;
  display: inline-block;
  width: 100%;
  select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 10px 15px;
    outline: 0;
    border: none;
    border-radius: 0px;
    background: $silver;
    appearance: none;
    height: 48px;
    &::-ms-expand {
      display: none;
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }    
  .select-arrow {
    position: absolute;
    top: 19px;
    right: 15px;
    width: 0px;
    height: 0px;
    border: solid $black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  select:disabled ~ .select-arrow {
    border-top-color: $silver;
  }
}

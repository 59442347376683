.top-content{
  p{
    margin: 35px 0px;
  }
  span{
    display: block;
  }
  button{
    margin-bottom: 35px;
  }
}
#main{
  font-size: 16px;
  line-height: 26px;
  .container{
    padding: 30px 15px;
  }
}
.left-content{
    padding: 0px 7px;
    background-color: $white;
    border: 1px solid $dusty_gray_opacity;
    >*{
      margin: 30px 0px;
    }
}
.right-content{
    background-color: $turquoise;
    color: $white;
    padding: 0px 7px;
    border: 1px solid $turquoise;

    >*{
      margin: 30px 0px;
    }
    a{
        color: $white;
    }
    span{
        display: block;
    }
    .logos {
      img {
        height: auto;
        max-width: 100px;
        width: 100%;
      }
      a {
        display: inline-block;
        &:first-of-type {
          margin-right: 35px;
          img {
            max-width: 135px;
          }
        }
      }
    }
}
.error-alert{
  background-color: $monza;
  color: $white;
  display: flex;
  align-items: center;
  padding: 10px;
  p{
    margin: 0px 5px 0px 20px;
  }
  span{
    display: block;
    &:first-of-type{
      font-weight: 600;
    }
  }
}
.ThreeRows{
  .select{
    +.select{
      margin-top: 25px;
    }
  }
}
@media screen and (min-width: $tablet_width_breakpoint) {
  #main{
    .container{
      padding: 50px 15px;
    }
    .pagetitle{
        margin-bottom: 5px;
    }
  }
  .right-content{
      padding: 0px 30px;
      >*{
        margin: 50px 0px;
      }
      >span{
        display: inline;
      }
  }
  .left-content{
      padding: 0px 15px;
      >*{
        margin: 50px 0px;
      }
  }
  .ThreeRows{
    grid-column-gap: 30px;
    .select{
      +.select{
        margin-top: 0px;
      }
    }
  }
}
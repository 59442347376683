.card-image{
  border-radius: 50%;
  height: 160px;
  object-fit: cover;
  width: 160px;
}
@media screen and (min-width: $tablet_width_breakpoint) {
  .card-image{
    width: 155px;
    height: 155px;
  }
}